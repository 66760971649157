import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import video from "../images/Cruiser-Camper-Video.mp4"
import Form from "../components/form"
import poster from "../images/thumbnail.webp"
import CruiserBlogComponent from "../components/blog"


const IndexPage = () => (
  <Layout>
    <Seo title="Camper Hire Adelaide" />


    <section className="section shapedividers_com-9676 has-background-sand">
      <div className="container">

        <div className="columns is-vcentered " data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="300"
            data-sal-easing="ease">
          <div className="column has-text-centered-touch">
            <h1 className="py-0 is-size-4">South Australia's Most Affordable</h1>
            <h1 className="is-size-2 is-size-3-touch pb-5 line-under-text">
             4x4 Landcruiser Hire
            </h1>
            
            <div className="columns">
              <div className="column is-4 is-offset-8 has-text-centered has-background-black">
                <h1 className="has-text-white is-size-4">From $140 A Day</h1>
              </div>
            </div>
            <div className="has-text-centered py-5">
              <a  href="https://www.camplify.com.au/rv/campervan-rental-adelaide-sa-cruiser-camper/16304" target="_blank"><button className="booking-btn has-text-centered">BOOK NOW!</button></a></div>
          </div>
          <div className="column has-text-centered">
            <figure >
            <StaticImage src="../images/Cruiser-Campers-Adelaide-10.jpeg" className="title-image" quality={95} width={450} /></figure>
            <span class="icon-text py-2">
              <span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>
              <span className="is-marker">Experience</span>
              <span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>
              <span className="is-marker">Quality</span>
              <span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>
              <span className="is-marker">Price</span>

            </span>
          </div>
        </div>

      </div>
     
    </section>
    <section className="pt-0">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
    </svg>
    </section>


    <section className="section " id="the-cruiser">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column">

            <video controls poster={poster} >

              <source src={video}
                type="video/mp4"  />

              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
          
  

          <div className="column is-overlap">
          
            <div className="box"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="300"
            data-sal-easing="ease">

              <h2 className="is-size-3">How We Started</h2>
              <p className="py-5">Cruiser Campers was founded by Tom in 2020, during the height of the Covid-19 pandemic. With overseas travel out of the picture for the foreseeable future, Tom found that there was a large desire for people to travel closer to home and explore their own backyards.
              </p>
              <p className="py-5"> Through having just purchased a Landcruiser to travel around Australia himself, and experiencing the true freedom felt by having the ability to make anywhere your home, Cruiser Campers was created.
              </p>

            </div>
           
          </div>
         
        </div>

      </div>
    </section>



    <section className="section has-background-light" id="the-cruiser">
      <div className="container">

       
        <h3 className="is-size-2 is-size-3-touch py-5 has-text-centered">
          The Cruiser
        </h3>
        <div className="column my-2 is-2 is-offset-5 has-text-centered has-background-black">
                <h1 className="has-text-white is-size-4">From $140 A Day</h1>
              </div>
        <div className="columns is-vcentered">
          <div className="column" data-sal="slide-right"
            data-sal-delay="100"
            data-sal-duration="300"
            data-sal-easing="ease">
            <figure className="py-5">
              <StaticImage src="../images/Cruiser-Campers-Adelaide-7.jpeg" className="" quality={95}  /></figure>
            <figure className="py-5">
              <StaticImage src="../images/Cruiser-Campers-Adelaide-11.jpeg" className="" quality={95} /></figure>
           
          </div>
          <div className="column" >
            <div className="box">

              <p className="py-5">
                <p>
                <h3 className="is-size-3 has-text-centered py-3">2013 Landcruiser <br />V8 Turbo Diesel</h3>
               <div className="columns py-3">
                 <div className="column">
                 <ul>
<li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>Sleeps 2 adults + child</li>
<li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>Seats 5</li>
                  </ul>
                 </div>
                 <div className="column">
                 <ul>
<li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>Manual transmission</li>
<li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>4x4</li>
                  </ul>
                 </div>
               </div>
               


                </p>
                <h2 className="is-size-3 pt-5">So what's included </h2><p>
                  <p className="py-3">We provide a fully equipped and set up camper with off grid capabilities. Off-grid means that the camper is set up to be fully self sufficient for a period of time - so you can escape the rat race and explore the most remote parts of Australia and still live like royalty. Our Landcruiser Wagon is fitted with:</p>
                  <ul>
                    <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>A premium rooftop tent (30 second setup)</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>Side and rear awnings</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>45L fridge / freezer</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>Picnic table</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>Premium camp chairs</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>Gas stove</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>20L onboard water</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>240V power supply</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>Cooking utensils + cutlery</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>Bedding supplied (byo pillows)</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>Multiple USB charging ports</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>First Aid Kit</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>No surchcharge for multiple drivers</li>
              <li><span class="icon">
                <i class="fas fa-check-circle"></i>
              </span>UHF</li>
                    
                  </ul>
                </p>
              </p>
            </div>
          </div>

        </div>

      </div>
    </section>

    <section className="section" id="cruiser-gallery">
      <div className="container">
        <div className="columns ">
          <div className="column is-12 has-text-centered ">
            <StaticImage src="../images/CruiserCamperslogolarge.png" width={300} />
            <div className="column"><a href="https:\\instagram.com\cruisercampers_aus"><div className="columns is-vcentered is-justify-content-center has-text-black">
              <div className="column is-narrow">
                <StaticImage src="../images/instagram.png" width={64} />
              </div>
              <div className="column is-narrow">
                <h2 className="is-size-3 is-size-4-touch">cruisercampers_aus</h2>
              </div>
              <div className="column is-narrow">
                <h2 className="is-size-3 is-size-4-touch">#cruisercampers</h2>
              </div>
            </div></a></div>
          </div>
        </div>

        <div className="columns is-vcentered is-multiline">
          <div className="column is-one-third">
            <StaticImage src="../images/Cruiser-Campers-Adelaide-1.jpeg" className="" quality={95} width={432} />
          </div>
          <div className="column is-one-third">
            <StaticImage src="../images/Cruiser-Campers-Adelaide-2.jpeg" className="" quality={95} width={432} />
          </div>

          <div className="column is-one-third">
            <StaticImage src="../images/Cruiser-Campers-Adelaide-3.jpeg" className="" quality={95} width={432} />
          </div>

          <div className="column is-one-third">
            <StaticImage src="../images/Cruiser-Campers-Adelaide-6.jpeg" className="" quality={95} width={432} />
          </div>

          <div className="column is-one-third">
            <StaticImage src="../images/Cruiser-Campers-Adelaide-7.jpeg" className="" quality={95} width={432} />
          </div>

          <div className="column is-one-third">
            <StaticImage src="../images/Cruiser-Campers-Adelaide-11.jpeg" className="" quality={95} width={432} />
          </div>

          <div className="column is-one-third">
            <StaticImage src="../images/Cruiser-Campers-Adelaide-4.jpeg" className="" quality={95} width={432} />
          </div>

          <div className="column">
            <StaticImage src="../images/Cruiser-Campers-Adelaide-8.jpeg" className="" quality={95} width={432} />
          </div>

          <div className="column">
            <StaticImage src="../images/Cruiser-Campers-Adelaide-9.jpeg" className="" quality={95} width={432} />
          </div>

        </div>



      </div>
    </section>

    <section className="section has-background-light" id="cruiser-difference">
      <div className="container">
        <div className="box has-text-centered">
          <h2 className="is-size-3">The difference</h2>
          <p>Unlike other rental companies, our camper comes at a fraction of the cost but has lots more to offer! Everything you need for your camping trip is included in our camper at no extra cost - when other companies might charge you extra for all the same creature comforts! The only thing you need to pack is your camera and your clothes - the rest is sorted!  </p><p>Secondly, we are a small business and a lot of care goes into looking after our camper to ensure it’s in top notch shape for each adventure. Finally, a quality customer experience is our number one priority, with local knowledge and many years of personal camping experience, we aim to provide you with a premium adventure that you will remember for years to come. If you need help planning where to take the camper, Tom is always happy share some tips!
          </p>
        </div>

        <div className="columns">
          <div className="column has-text-centered">
           
            <div className="box"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="300"
            data-sal-easing="ease"
              >

              <h3 className="is-size-3">Experience</h3>
              <StaticImage src="../images/tent-icon.png" width={80} />
              <p>We want to provide an unforgettable experience that will be forever embedded into your memory.</p>
            </div>
           
          </div>
          <div className="column has-text-centered">
        
            <div className="box"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="600"
            data-sal-easing="ease">

              <h3 className="is-size-3">Quality</h3>
              <StaticImage src="../images/quality-icon.png" width={80} />
              <p>We strive to provide the best quality vehicle and equipment.</p>

            </div>
            
          </div>
          <div className="column has-text-centered">
          
            <div className="box" data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="400"
            data-sal-easing="ease"
            >
              <h3 className="is-size-3">Price</h3>
              <StaticImage src="../images/price-icon.png" width={80} />
              <p>We provide the best value for money camping experience on the market.</p>

            </div>
            
          </div>
        </div></div>
    </section>



  

    <section className="section pattern-background" id="cruiser-contact">
    <div className="container py-5">
        <div className="columns">
          <div className="column py-5 has-text-centered">
          <a href="https://www.camplify.com.au/rv/campervan-rental-adelaide-sa-cruiser-camper/16304" target="_blank"><button className="booking-btn">BOOK NOW!</button></a>
          </div>
        </div>
      </div>
      <div className="container box">
        
        <h3 className="is-size-2 is-size-3-touch has-text-centered py-5">Got A Question?</h3>

        <div className="columns is-vcentered">
          <div className="column">
            <Form />
          </div>
          <div className="column has-text-centered"
          data-sal="slide-left"
          data-sal-delay="100"
          data-sal-duration="500"
          data-sal-easing="ease">
            <StaticImage src="../images/contact-cruiser-campers.png" width={500} />
          </div>
        </div>
      </div>
    </section>


  </Layout>
)

export default IndexPage
