import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"


const Form = () => (
 <div>
   <div className="columns is-multiline" id="bookingform">
   <div className="column is-12">
   
   <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
<input type="hidden" name="bot-field" />
<input type="hidden" name="form-name" value="contact" />
   <div className="field">
   <label className="label">Your Name(s)</label>
 <div className="control">
   <input className="input" type="text" placeholder="Your Name" name="Name" />
 </div>
</div>

<div className="field">
<label className="label">Best Email</label>
 <div className="control">
   <input className="input" type="email" placeholder="Your Email" name="Email" />
 </div>
</div>
<div className="field">
<label className="label">Your Phone Number</label>
 <div className="control">
   <input className="input" type="phone" placeholder="Your Phone Number" name="phone-number" />
 </div>
</div>
 
  
     <div className="field">
<label className="label">When Are You Leaving?</label>
 <div className="control">
   <input className="input" type="date" placeholder="Your Name" name= "date"/>
 </div>
</div>

  
 <div className="field">
<label className="label">How Many Nights Are You Going For?</label>
 <div className="control">
   <input className="input" type="phone" placeholder="Number Of Nights" name="location"/>
 </div>
</div>

<div className="field">
<label className="label">How Can We Help?</label>
 <div className="control">
   <input className="input" type="text" placeholder="How Can We Help" name="location"/>
 </div>
</div>

 <div class="control">
 <button class="button is-outlined is-rounded" type="submit">Submit</button>
</div>

</form>
</div>
</div>
 </div>
)

export default Form
